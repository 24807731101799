import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getEstablishmentId, getEstablishmentLng } from "../store/selectors";
import countriesLng from '../assets/countries_lng';
import { useContext } from "react";
import { useEffect } from "react";
import { isTranslated, translateProp, translateStaticText } from "./utils";
import { fetchStaticTranslations } from "../actions/staticTextActions";

const LngContext = React.createContext();

export const useLng = () => {
    const context = useContext(LngContext);

    return context
}

export const LngProvider = ({children}) => {
    const defaultLanguage = window.localStorage.getItem('vdd-lang');
    const lng = useSelector(getEstablishmentLng);
    const [userLng, setULng] = useState(defaultLanguage);
    const [staticTexts, setStaticTexts] = useState({});
    const establishment = useSelector(getEstablishmentId);

    useEffect(() => {
      const getTranslations = async() => {
        const {ok, translations, err } = await fetchStaticTranslations(establishment)
        if(!ok){
          console.log("🛠️ ~ getTranslations ~ err", err);
          return;
        }

        setStaticTexts(translations);
      }

      if(establishment){
        getTranslations();
      }
      
    },[establishment])

    useEffect(() => {
        if(lng && lng.length > 0 && !userLng){
            setULng(lng[0]);
        }
    },[lng, userLng])

    const value = {
        userLng,
        onChangeLng : selected => {
            const index = countriesLng.findIndex(c => c.code === selected);
            const newLng = index !== -1  ? selected : lng[0]
            setULng(newLng);
            window.localStorage.setItem('vdd-lang', newLng);
        },
        t : (prop) => translateProp(prop, userLng),
        tstatic : (text) => translateStaticText(staticTexts, text, userLng),
        isTranslated : (data) => isTranslated(data, userLng)
    }

    return (
        <LngContext.Provider value={value}>
            {children}
        </LngContext.Provider>
    )
}
