// Translates translatable prop
export const translateProp = (prop, lng) => {
    if(prop.isTranslatable && prop[lng]){
        return prop[lng]
    }
    
    return '';
}

// translates static text
export const translateStaticText = (staticTexts, text, lng) => {
    if(staticTexts[text] && staticTexts[text].translations[lng]){
        return staticTexts[text].translations[lng]
    }

    return text;
}

// Checks if is secure to translate data
export const isTranslated = (data, lng) => {
    let translated = true;
    const keys = Object.keys(data);

    let i = 0;
    while(translated && i<keys.length){
        // its suposed to be translated but it isnt
        if(data[keys[i]]?.isTranslatable && !translateProp(data[keys[i]], lng)){
            translated = false;
        }
        i++;
    }
    return translated;
}
