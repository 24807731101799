import { keyBy } from 'lodash';
import { API } from '../constants/api';
import { SET_ALL_ROUTES, SET_CURRENT_ROUTE } from '../constants';
import { axios } from '../connection/ConnectionHandler';

export const fetchAllRoutes = (id) => {
    return async dispatch => {
        const response = await
            axios.get(API.establishment.routes(id)).then(response => {
                const routes = keyBy(response.data, 'keyapp');
                const first = Object.keys(routes)[0]
                dispatch({ type: SET_ALL_ROUTES, payload: routes });
                dispatch({ type : SET_CURRENT_ROUTE, payload : routes[first]})
            }).catch(err => {
                console.log(err)
                dispatch({ type: SET_ALL_ROUTES, payload: {} });
            });
        return response;
    };
};