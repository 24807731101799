import { handleActions } from 'redux-actions';
import {
    SET_CURRENT_ROUTE,
    SET_ALL_ROUTES,  SET_SIDE_MENU_STATE, SET_ESTABLISHMENT, 
    SET_ESTABLISHMENT_SCHEDULED,SET_ESTABLISHMENT_ZONE, SET_ESTABLISHMENT_INFO, 
    SET_ESTABLISHMENT_HOMEPAGE, SET_ESTABLISHMENT_SERVICES, SET_ESTABLISHMENT_RESTAURANTS, 
    SET_ESTABLISHMENT_PAGES, SET_TPV_CATS, SET_TPV_PLATES
} from './../../constants';


/* ESTABLISHMENT */
export const establishment = handleActions({
    [SET_ESTABLISHMENT]: (s, action) => action.payload,
}, null)

export const homepage = handleActions({
    [SET_ESTABLISHMENT_HOMEPAGE] : (s, action) => action.payload,
}, null)

export const info = handleActions({
    [SET_ESTABLISHMENT_INFO]: (state, action) => action.payload,
},[])

export const zones = handleActions({
    [SET_ESTABLISHMENT_ZONE] : (state, action) => action.payload,
}, {})

export const scheduled = handleActions({
    [SET_ESTABLISHMENT_SCHEDULED] : (state, action) => action.payload,
}, {})

export const services = handleActions({
    [SET_ESTABLISHMENT_SERVICES] : (state, action) => action.payload,
}, null)

export const restaurants = handleActions({
    [SET_ESTABLISHMENT_RESTAURANTS] : (state, action) => action.payload,
},{})

export const pages = handleActions({
    [SET_ESTABLISHMENT_PAGES] : (state, action) => action.payload,
},{})

/* ROUTES */
export const currentRoute = handleActions({
    [SET_CURRENT_ROUTE] : (state, action) => action.payload,
},null);

export const routes = handleActions({
    [SET_ALL_ROUTES]: (state, action) => action.payload,
}, null);

/* VIRTUAL WAITER */
export const vw = handleActions({
    [SET_TPV_CATS] : (state, action) => ({...state, cats : action.payload}),
    [SET_TPV_PLATES] : (state, action) => ({...state, plates : action.payload}),
},{cats : [], plates : []})


/* APP */
export const app = handleActions({
    [SET_SIDE_MENU_STATE]: (state, action) => {
        return ({ ...state, isCollapsedSideMenu: action.payload });
    }
}, { isCollapsedSideMenu: true });