import { axios } from "../connection/ConnectionHandler";
import { CHAT_COOKIE } from "../constants"
import { API } from "../constants/api";
import { getCookie, deleteCookie, setCookie } from "../utils"

export const messageType = {
	REQUEST : 'REQUEST',
	RESPONSE : 'RESPONSE'
}

export const getChatSession = async() => {
	let session = await getCookie(CHAT_COOKIE);
	// check expiration
  if(session && session.exp < Date.now()){
		await deleteCookie(CHAT_COOKIE)
		session = null;
	}
	// check if session id valid
	if(session){
    const isValid = await axios.get(API.chat.validateSession, 
			{ headers : {
				"chat-session" : session.sessionId
			}}
		).then(({data}) => {
      return data.ok;
		})
		.catch(err => {
			return false;
		})

		if(!isValid){
			await deleteCookie(CHAT_COOKIE);
			session = null;
		}
	}
	return session;
}

export const initChatSession = async (loginData) => {
	return await axios.post(API.chat.initChatSession, {...loginData})
	.then(async ({data}) => {
		const { token, exp } = data;
		await setCookie(CHAT_COOKIE, {sessionId : token, exp : exp});
		return { ok : true, sessionId : token, exp : exp}
	})
	.catch(err => {
		return { ok : false, message : err?.response?.data}
	})
}

export const getSessionMessages = async(sessionId) => {
	return axios.get(API.chat.sessionMessages, 
		{ headers : {
			"chat-session" : sessionId
		}}
	)
	.then(({data}) => {
		return data;
	})
	.catch(err => {
		return [];
	})
}
  
