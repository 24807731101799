import { combineReducers } from 'redux';
import {
    currentRoute, routes, app, establishment,homepage,info,zones,pages,scheduled,
    services,restaurants,vw
} from "./Reducers";

export default combineReducers({
    app,
    currentRoute,
    routes,
    establishment,
    homepage,
    info,
    zones,
    scheduled,
    services,
    vw,
    restaurants,
    pages
});