import { keyBy } from "lodash";
import { axios } from "../connection/ConnectionHandler";
import {
    SET_ESTABLISHMENT, SET_ESTABLISHMENT_HOMEPAGE, SET_ESTABLISHMENT_INFO, SET_ESTABLISHMENT_RESTAURANTS, 
    SET_ESTABLISHMENT_SCHEDULED, SET_ESTABLISHMENT_SERVICES, SET_ESTABLISHMENT_ZONE, SET_ESTABLISHMENT_PAGES,
    SET_TPV_CATS,
} from "../constants";
import { API } from "../constants/api";
import { fetchAllRoutes } from "./routeAction";


// INFO
export const fetchEstablishmentData = (id) => {
    return async dispatch => {
        const response = await dispatch(fetchEstablishment(id));
        dispatch(fetchEstablishmentHomePage(id));
        dispatch(fetchEstablishmentInfo(id));
        dispatch(fetchEstablishmentZones(id));
        dispatch(fetchEstablishmentScheduled(id));
        dispatch(fetchEstablishmentPages(id));
        dispatch(fetchEstablishmentServices(id));
        dispatch(fetchEstablishmentRestaurants(id));
        dispatch(fetchAllRoutes(id));
        return response;
    };
};

const fetchEstablishment = (id) => {
    return async dispatch => {
        axios.get(API.establishment.data(id))
        .then(async response => {

            const { vw_info } = response.data;

            if(vw_info.hasVw){
                dispatch(fetchEstablishmentMenuCatTPV(vw_info.id));
            }
            dispatch({ type: SET_ESTABLISHMENT, payload: response.data })
            return 200;
        })
        .catch(err => {
            console.log(err)
            return false;
        });
    };
};

const fetchEstablishmentMenuCatTPV = (id) => {
    return async dispatch => {
        await axios.get(API.vw.categories(id))
        .then(response => {
            dispatch({ type : SET_TPV_CATS, payload: response.data})
        })
        .catch(err => console.log(err));
    }
}

const fetchEstablishmentHomePage = (id) => {
    return dispatch => {
        axios.get(API.establishment.homepage(id))
        .then(response => {
            dispatch({type : SET_ESTABLISHMENT_HOMEPAGE, payload : response.data});
        })
        .catch(err => console.log(err))
    }
}

// INFO
const fetchEstablishmentInfo = (id) => {
    return dispatch => {
        axios.get(API.establishment.info(id))
            .then(response => dispatch({ type: SET_ESTABLISHMENT_INFO, payload: response.data }))
            .catch(err => console.log(err));
    };
};

// ZONES
const fetchEstablishmentZones = (id) => {
    return dispatch => {
        axios.get(API.establishment.zone(id))
            .then(response => {
                const zones = keyBy(response.data, '_id');
                dispatch({ type: SET_ESTABLISHMENT_ZONE, payload: zones })
            })
            .catch(err => console.log(err));
    };
};

// SCHEDULED
const fetchEstablishmentScheduled = (id) => {
    return dispatch => {
        axios.get(API.establishment.scheduled(id))
            .then(response => dispatch({ type: SET_ESTABLISHMENT_SCHEDULED, payload: keyBy(response.data,'_id')}))
            .catch(err => console.log(err));
    };
};

// SERVICES
const fetchEstablishmentServices = (id) => {
    return dispatch => {
        axios.get(API.establishment.service(id))
            .then(response => dispatch({ type: SET_ESTABLISHMENT_SERVICES, payload: response.data }))
            .catch(err => console.log(err));
    };
};

// RESTAURANTS
const fetchEstablishmentRestaurants = (id) => {
    return dispatch => {
        axios.get(API.establishment.restaurant(id))
            .then(response => dispatch({type : SET_ESTABLISHMENT_RESTAURANTS, payload: keyBy(response.data,'_id')}))
            .catch(err => console.log(err));
    }   
}

// PAGES
const fetchEstablishmentPages = (id) => {
    return dispatch => {
        axios.get(API.establishment.page(id))
        .then(response => {
            dispatch({type : SET_ESTABLISHMENT_PAGES, payload: response.data})
        })
        .catch(err => {
            console.log(err);
        })
    }
}