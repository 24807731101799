// https://testing-core.deploy.kuarasoftware.es/

export const API = {
    establishment: {
        data: (id) =>  `/establishment/${id}`,
        homepage: (id) =>  `/homepage/${id}`,
        info: (id) =>  `/info/all/${id}`,
        zone: (id) =>  `/zone/all/${id}`,
        scheduled: (id) =>  `/scheduled/all/${id}`,
        service: (id) =>  `/service/all/${id}`,
        restaurant : (id) => `/restaurant/all/${id}`,
        page : (id) => `/page/all/${id}`,
        routes : (id) => `/routes/all/${id}`,
        staticText : (id) =>  `/translation/${id}`,
    },
    chat : {
        validateSession : `/chat/validate`,
        initChatSession : `/chat/init`,
        sessionMessages : `/chat/client-messages`,
    },
    vw : {
        tpvData : (id) => `https://apicheckin.kuarasoftware.es/carta/getall/${id}`,
        categories : (id) => `https://apicheckin.kuarasoftware.es/tipoPlato/getall/${id}`
    }
};
