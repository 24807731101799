/* BASIC APP CONSTANTS */
export const APP_COOKIE_NAME = 'VDD_ESTABLISHMENT';
export const CHAT_COOKIE = 'CHAT_TOKEN';
export const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE';
export const SET_SIDE_MENU_STATE = 'SET_SIDE_MENU_STATE';
export const SHOW_ACTION_MENU = 'SHOW_ACTION_MENU';
export const HIDE_ACTION_MENU = 'HIDE_ACTION_MENU';
export const SHOW_BOTTOM_TOOLBAR = 'SHOW_BOTTOM_TOOLBAR';
export const HIDE_BOTTOM_TOOLBAR = 'HIDE_BOTTOM_TOOLBAR';

export const REGISTER_DATA = 'REGISTER_DATA';

/* <---- BASIC APP CONSTANTS ----> */

/* ESTABLISHMENT */
export const SET_ESTABLISHMENT = 'SET_ESTABLISHMENT';
export const SET_ESTABLISHMENT_HOMEPAGE  = 'SET_ESTABLISHMENT_HOMEPAGE';
export const SET_ESTABLISHMENT_INFO = 'SET_ESTABLISHMENT_INFO';
export const SET_ESTABLISHMENT_ZONE = 'SET_ESTABLISHMENT_ZONE';
export const SET_ESTABLISHMENT_SCHEDULED = 'SET_ESTABLISHMENT_SCHEDULED';
export const SET_ESTABLISHMENT_SERVICES = 'SET_ESTABLISHMENT_SERVICES';
export const SET_ESTABLISHMENT_RESTAURANTS = 'SET_ESTABLISHMENT_RESTAURANTS';
export const SET_ESTABLISHMENT_PAGES = 'SET_ESTABLISHMENT_PAGES';
/* <---- ESTABLISHMENT ----> */

/* <---- VIRTUAL WAITER ----> */
export const SET_TPV_CATS = 'SET_TPV_CATS';
export const SET_TPV_PLATES = 'SET_PLATES';

/* ROUTES */
export const SET_ALL_ROUTES = 'SET_ALL_ROUTES';
/* <---- ROUTES ----> */



