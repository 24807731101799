import React, { useContext, useEffect, useState, useCallback } from 'react';
import { getChatSession, getSessionMessages, initChatSession } from '../../actions/chatActions';
import socketIOClient from "socket.io-client";
import { BASE_URL } from '../../connection/ConnectionHandler';
import { useSelector } from 'react-redux';
import { getEstablishmentId } from '../../store/selectors'; 

const ChatContext = React.createContext({});

export const useChat = () => {
	const context = useContext(ChatContext);

	return context
}

export const ChatProvider = ({children}) => {
	const [session, setSession] = useState(null);
	const [loading, setLoading] = useState(true);
	const [socket, setSocket] = useState();
	const [messages, setMessages] = useState([]);
	const establishmentId = useSelector(getEstablishmentId)

	const enableSocket = useCallback(() => {
		if(establishmentId && session){
			const socketURL = `${BASE_URL}/${establishmentId}?type=CLIENT&sessionId=${session.sessionId}`;
			const io = socketIOClient(socketURL);
			setSocket(io);
		}
	},[establishmentId, session])

	const getMessages = useCallback(async () => {
		setLoading(true);
		setMessages( await getSessionMessages(session.sessionId))
		setLoading(false)
	},[session?.sessionId])

	const checkChatSession = useCallback(async() => {
		const session = await getChatSession();
		if(session != null){
			setSession(session)
		}
		setLoading(false);
	},[])

	// socket side effects
	useEffect(() => {
		if(socket){
			socket.on('request-failed', (err) => {
				console.log(err);
			})

			socket.on('request-success', (data) => {
				const { storedMessage } = data;
				setMessages(prev => prev.concat(storedMessage));
			})

			socket.on('receive-response', (data) => {
				setMessages(prev => prev.concat(data));
			})
		}

		return () => {
			if(socket){	
				socket.disconnect();
			}
		}
	},[socket])

	// session side effects
	useEffect(() => {
		if(session != null){
			enableSocket();
			getMessages();
		}
		
	},[session, enableSocket, getMessages])

	useEffect(() => {
		checkChatSession();
	},[checkChatSession]);

	const sendMessage = async(msg) => {
		const message = {
			sessionId : session.sessionId,
			msg,
			type : "REQUEST"
		}

		socket.emit("send-request", {message : message});
	}

	const startChat = async(data) => {
		setLoading(true);
		const {sessionId, exp, ok, message} = await initChatSession(data);
		if(ok) {
			setSession({sessionId, exp});
		}
		setLoading(false);
		return {ok, error : message};
	}

	const value = {
		loading,
		setLoading,
		...session,
		startChat,
		sendMessage,
		messages,
		io : socket
	}

	return(
		<ChatContext.Provider value={value}>
			{children}
		</ChatContext.Provider>
	)
}