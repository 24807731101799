const countriesLng = [
  {
    "language": {
      "original": "Azərbaycan",
      "es": "Azerí",
      "en": "Azerbaijani"
    },
    "code": "az",
    "name": {
      "common": "Azerbaijan",
      "official": "Republic of Azerbaijan",
      "nativeName": {
        "aze": {
          "official": "Azərbaycan Respublikası",
          "common": "Azərbaycan"
        },
        "rus": {
          "official": "Азербайджанская Республика",
          "common": "Азербайджан"
        }
      }
    }
  },
  {
    "language": {
      "original": "Afrikaans",
      "es": "Afrikaans",
      "en": "Afrikaans"
    },
    "code": "af",
    "name": {
      "common": "Afghanistan",
      "official": "Islamic Republic of Afghanistan",
      "nativeName": {
        "prs": {
          "official": "جمهوری اسلامی افغانستان",
          "common": "افغانستان"
        },
        "pus": {
          "official": "د افغانستان اسلامي جمهوریت",
          "common": "افغانستان"
        },
        "tuk": {
          "official": "Owganystan Yslam Respublikasy",
          "common": "Owganystan"
        }
      }
    }
  },
  {
    "language": {
      "original": "Shqip",
      "es": "Albanés",
      "en": "Albanian"
    },
    "code": "sq"
  },
  {
    "language": {
      "original": "Deutsch",
      "es": "Alemán",
      "en": "German"
    },
    "code": "de",
    "name": {
      "common": "Germany",
      "official": "Federal Republic of Germany",
      "nativeName": {
        "deu": {
          "official": "Bundesrepublik Deutschland",
          "common": "Deutschland"
        }
      }
    }
  },
  {
    "language": {
      "original": "Alemannisch",
      "es": "Alsaciano"
    },
    "code": "ls",
    "name": {
      "common": "Lesotho",
      "official": "Kingdom of Lesotho",
      "nativeName": {
        "eng": {
          "official": "Kingdom of Lesotho",
          "common": "Lesotho"
        },
        "sot": {
          "official": "Kingdom of Lesotho",
          "common": "Lesotho"
        }
      }
    }
  },
  {
    "language": {
      "original": "አማርኛ",
      "es": "Amárico",
      "en": "Amharic"
    },
    "code": "am",
    "name": {
      "common": "Armenia",
      "official": "Republic of Armenia",
      "nativeName": {
        "hye": {
          "official": "Հայաստանի Հանրապետություն",
          "common": "Հայաստան"
        }
      }
    }
  },
  {
    "language": {
      "original": "Anglo Saxon",
      "es": "Anglosajón",
      "en": "Ndonga"
    },
    "code": "ng",
    "name": {
      "common": "Nigeria",
      "official": "Federal Republic of Nigeria",
      "nativeName": {
        "eng": {
          "official": "Federal Republic of Nigeria",
          "common": "Nigeria"
        }
      }
    }
  },
  {
    "language": {
      "original": "العربية",
      "es": "Árabe",
      "en": "Arabic"
    },
    "code": "ar",
    "name": {
      "common": "Argentina",
      "official": "Argentine Republic",
      "nativeName": {
        "grn": {
          "official": "Argentine Republic",
          "common": "Argentina"
        },
        "spa": {
          "official": "República Argentina",
          "common": "Argentina"
        }
      }
    }
  },
  {
    "language": {
      "original": "Aragonés",
      "es": "Aragonés",
      "en": "Aragonese"
    },
    "code": "an"
  },
  {
    "language": {
      "original": "Հայերեն",
      "es": "Armenio",
      "en": "Armenian"
    },
    "code": "hy"
  },
  {
    "language": {
      "original": "seSotho",
      "es": "Sesotho",
      "en": "Southern Sotho"
    },
    "code": "st",
    "name": {
      "common": "São Tomé and Príncipe",
      "official": "Democratic Republic of São Tomé and Príncipe",
      "nativeName": {
        "por": {
          "official": "República Democrática do São Tomé e Príncipe",
          "common": "São Tomé e Príncipe"
        }
      }
    }
  },
  {
    "language": {
      "original": "Aymar",
      "es": "Aymara",
      "en": "Aymara"
    },
    "code": "ay"
  },
  {
    "language": {
      "original": "Plattdüütsch",
      "es": "Bajo"
    },
    "code": "ds"
  },
  {
    "language": {
      "original": "বাংলা",
      "es": "Bengalí",
      "en": "Bengali"
    },
    "code": "bn",
    "name": {
      "common": "Brunei",
      "official": "Nation of Brunei, Abode of Peace",
      "nativeName": {
        "msa": {
          "official": "Nation of Brunei, Abode Damai",
          "common": "Negara Brunei Darussalam"
        }
      }
    }
  },
  {
    "language": {
      "original": "Беларуская",
      "es": "Bielorruso",
      "en": "Belarusian"
    },
    "code": "be",
    "name": {
      "common": "Belgium",
      "official": "Kingdom of Belgium",
      "nativeName": {
        "deu": {
          "official": "Königreich Belgien",
          "common": "Belgien"
        },
        "fra": {
          "official": "Royaume de Belgique",
          "common": "Belgique"
        },
        "nld": {
          "official": "Koninkrijk België",
          "common": "België"
        }
      }
    }
  },
  {
    "language": {
      "original": "Myanmasa",
      "es": "Birmano",
      "en": "Burmese"
    },
    "code": "my",
    "name": {
      "common": "Malaysia",
      "official": "Malaysia",
      "nativeName": {
        "eng": {
          "official": "Malaysia",
          "common": "Malaysia"
        },
        "msa": {
          "official": "مليسيا",
          "common": "مليسيا"
        }
      }
    }
  },
  {
    "language": {
      "original": "Bosanski",
      "es": "Bosnio",
      "en": "Bosnian"
    },
    "code": "bs",
    "name": {
      "common": "Bahamas",
      "official": "Commonwealth of the Bahamas",
      "nativeName": {
        "eng": {
          "official": "Commonwealth of the Bahamas",
          "common": "Bahamas"
        }
      }
    }
  },
  {
    "language": {
      "original": "Brezhoneg",
      "es": "Bretón",
      "en": "Breton"
    },
    "code": "br",
    "name": {
      "common": "Brazil",
      "official": "Federative Republic of Brazil",
      "nativeName": {
        "por": {
          "official": "República Federativa do Brasil",
          "common": "Brasil"
        }
      }
    }
  },
  {
    "language": {
      "original": "Български",
      "es": "Búlgaro",
      "en": "Bulgarian"
    },
    "code": "bg",
    "name": {
      "common": "Bulgaria",
      "official": "Republic of Bulgaria",
      "nativeName": {
        "bul": {
          "official": "Република България",
          "common": "България"
        }
      }
    }
  },
  {
    "language": {
      "original": "ಕನ್ನಡ",
      "es": "Canarés",
      "en": "Kannada"
    },
    "code": "kn",
    "name": {
      "common": "Saint Kitts and Nevis",
      "official": "Federation of Saint Christopher and Nevis",
      "nativeName": {
        "eng": {
          "official": "Federation of Saint Christopher and Nevis",
          "common": "Saint Kitts and Nevis"
        }
      }
    }
  },
  {
    "language": {
      "original": "Català",
      "es": "Catalán",
      "en": "Catalan; Valencian"
    },
    "code": "ca",
    "name": {
      "common": "Canada",
      "official": "Canada",
      "nativeName": {
        "eng": {
          "official": "Canada",
          "common": "Canada"
        },
        "fra": {
          "official": "Canada",
          "common": "Canada"
        }
      }
    }
  },
  {
    "language": {
      "original": "Chamoru",
      "es": "Chamorro",
      "en": "Chamorro"
    },
    "code": "ch",
    "name": {
      "common": "Switzerland",
      "official": "Swiss Confederation",
      "nativeName": {
        "fra": {
          "official": "Confédération suisse",
          "common": "Suisse"
        },
        "gsw": {
          "official": "Schweizerische Eidgenossenschaft",
          "common": "Schweiz"
        },
        "ita": {
          "official": "Confederazione Svizzera",
          "common": "Svizzera"
        },
        "roh": {
          "official": "Confederaziun svizra",
          "common": "Svizra"
        }
      }
    }
  },
  {
    "language": {
      "original": "Česky",
      "es": "Checo",
      "en": "Czech"
    },
    "code": "cs"
  },
  {
    "language": {
      "original": "Hrvatski",
      "es": "Croata",
      "en": "Croatian"
    },
    "code": "hr",
    "name": {
      "common": "Croatia",
      "official": "Republic of Croatia",
      "nativeName": {
        "hrv": {
          "official": "Republika Hrvatska",
          "common": "Hrvatska"
        }
      }
    }
  },
  {
    "language": {
      "original": "中文",
      "es": "Chino",
      "en": "Chinese"
    },
    "code": "zh"
  },
  {
    "language": {
      "original": "한국어",
      "es": "Coreano",
      "en": "Korean"
    },
    "code": "ko"
  },
  {
    "language": {
      "original": "Corsu",
      "es": "Corso",
      "en": "Corsican"
    },
    "code": "co",
    "name": {
      "common": "Colombia",
      "official": "Republic of Colombia",
      "nativeName": {
        "spa": {
          "official": "República de Colombia",
          "common": "Colombia"
        }
      }
    }
  },
  {
    "language": {
      "original": "Kurdî",
      "es": "Curdo",
      "en": "Kurdish"
    },
    "code": "ku"
  },
  {
    "language": {
      "original": "Dansk",
      "es": "Danés",
      "en": "Danish"
    },
    "code": "da"
  },
  {
    "language": {
      "original": "Slovenčina",
      "es": "Eslovaco",
      "en": "Slovak"
    },
    "code": "sk",
    "name": {
      "common": "Slovakia",
      "official": "Slovak Republic",
      "nativeName": {
        "slk": {
          "official": "Slovenská republika",
          "common": "Slovensko"
        }
      }
    }
  },
  {
    "language": {
      "original": "Slovenščina",
      "es": "Esloveno",
      "en": "Slovene"
    },
    "code": "sl",
    "name": {
      "common": "Sierra Leone",
      "official": "Republic of Sierra Leone",
      "nativeName": {
        "eng": {
          "official": "Republic of Sierra Leone",
          "common": "Sierra Leone"
        }
      }
    }
  },
  {
    "language": {
      "original": "Español",
      "es": "Español",
      "en": "Spanish; Castilian"
    },
    "code": "es",
    "name": {
      "common": "Spain",
      "official": "Kingdom of Spain",
      "nativeName": {
        "spa": {
          "official": "Reino de España",
          "common": "España"
        }
      }
    }
  },
  {
    "language": {
      "original": "Esperanto",
      "es": "Esperanto",
      "en": "Esperanto"
    },
    "code": "eo"
  },
  {
    "language": {
      "original": "Eesti",
      "es": "Estonio",
      "en": "Estonian"
    },
    "code": "et",
    "name": {
      "common": "Ethiopia",
      "official": "Federal Democratic Republic of Ethiopia",
      "nativeName": {
        "amh": {
          "official": "የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ",
          "common": "ኢትዮጵያ"
        }
      }
    }
  },
  {
    "language": {
      "original": "Euskara",
      "es": "Euskera",
      "en": "Basque"
    },
    "code": "eu"
  },
  {
    "language": {
      "original": "Føroyskt",
      "es": "Feroés",
      "en": "Faroese"
    },
    "code": "fo",
    "name": {
      "common": "Faroe Islands",
      "official": "Faroe Islands",
      "nativeName": {
        "dan": {
          "official": "Færøerne",
          "common": "Færøerne"
        },
        "fao": {
          "official": "Føroyar",
          "common": "Føroyar"
        }
      }
    }
  },
  {
    "language": {
      "original": "Na Vosa Vakaviti",
      "es": "Fiyiano",
      "en": "Fijian"
    },
    "code": "fj",
    "name": {
      "common": "Fiji",
      "official": "Republic of Fiji",
      "nativeName": {
        "eng": {
          "official": "Republic of Fiji",
          "common": "Fiji"
        },
        "fij": {
          "official": "Matanitu Tugalala o Viti",
          "common": "Viti"
        },
        "hif": {
          "official": "रिपब्लिक ऑफ फीजी",
          "common": "फिजी"
        }
      }
    }
  },
  {
    "language": {
      "original": "Suomi",
      "es": "Finlandés",
      "en": "Finnish"
    },
    "code": "fi",
    "name": {
      "common": "Finland",
      "official": "Republic of Finland",
      "nativeName": {
        "fin": {
          "official": "Suomen tasavalta",
          "common": "Suomi"
        },
        "swe": {
          "official": "Republiken Finland",
          "common": "Finland"
        }
      }
    }
  },
  {
    "language": {
      "original": "Français",
      "es": "Francés",
      "en": "French"
    },
    "code": "fr",
    "name": {
      "common": "France",
      "official": "French Republic",
      "nativeName": {
        "fra": {
          "official": "République française",
          "common": "France"
        }
      }
    }
  },
  {
    "language": {
      "original": "Frysk",
      "es": "Frisón",
      "en": "Western Frisian"
    },
    "code": "fy"
  },
  {
    "language": {
      "original": "Cymraeg",
      "es": "Galés",
      "en": "Welsh"
    },
    "code": "cy",
    "name": {
      "common": "Cyprus",
      "official": "Republic of Cyprus",
      "nativeName": {
        "ell": {
          "official": "Δημοκρατία της Κύπρος",
          "common": "Κύπρος"
        },
        "tur": {
          "official": "Kıbrıs Cumhuriyeti",
          "common": "Kıbrıs"
        }
      }
    }
  },
  {
    "language": {
      "original": "Galego",
      "es": "Gallego",
      "en": "Galician"
    },
    "code": "gl",
    "name": {
      "common": "Greenland",
      "official": "Greenland",
      "nativeName": {
        "kal": {
          "official": "Kalaallit Nunaat",
          "common": "Kalaallit Nunaat"
        }
      }
    }
  },
  {
    "language": {
      "original": "ქართული",
      "es": "Georgiano",
      "en": "Georgian"
    },
    "code": "ka"
  },
  {
    "language": {
      "original": "Ελληνικά",
      "es": "Griego",
      "en": "Greek, Modern"
    },
    "code": "el"
  },
  {
    "language": {
      "original": "Avañe'ẽ",
      "es": "Guaraní",
      "en": "Guaraní"
    },
    "code": "gn",
    "name": {
      "common": "Guinea",
      "official": "Republic of Guinea",
      "nativeName": {
        "fra": {
          "official": "République de Guinée",
          "common": "Guinée"
        }
      }
    }
  },
  {
    "language": {
      "original": "ગુજરાતી",
      "es": "Gujaratí",
      "en": "Gujarati"
    },
    "code": "gu",
    "name": {
      "common": "Guam",
      "official": "Guam",
      "nativeName": {
        "cha": {
          "official": "Guåhån",
          "common": "Guåhån"
        },
        "eng": {
          "official": "Guam",
          "common": "Guam"
        },
        "spa": {
          "official": "Guam",
          "common": "Guam"
        }
      }
    }
  },
  {
    "language": {
      "original": "עברית",
      "es": "Hebreo",
      "en": "Hebrew (modern)"
    },
    "code": "he"
  },
  {
    "language": {
      "original": "हिन्दी",
      "es": "Hindi",
      "en": "Hindi"
    },
    "code": "hi"
  },
  {
    "language": {
      "original": "Nederlands",
      "es": "Holandés",
      "en": "Dutch"
    },
    "code": "nl",
    "name": {
      "common": "Netherlands",
      "official": "Kingdom of the Netherlands",
      "nativeName": {
        "nld": {
          "official": "Koninkrijk der Nederlanden",
          "common": "Nederland"
        }
      }
    }
  },
  {
    "language": {
      "original": "Magyar",
      "es": "Húngaro",
      "en": "Hungarian"
    },
    "code": "hu",
    "name": {
      "common": "Hungary",
      "official": "Hungary",
      "nativeName": {
        "hun": {
          "official": "Magyarország",
          "common": "Magyarország"
        }
      }
    }
  },
  {
    "language": {
      "original": "Ido",
      "es": "Ido",
      "en": "Ido"
    },
    "code": "io",
    "name": {
      "common": "British Indian Ocean Territory",
      "official": "British Indian Ocean Territory",
      "nativeName": {
        "eng": {
          "official": "British Indian Ocean Territory",
          "common": "British Indian Ocean Territory"
        }
      }
    }
  },
  {
    "language": {
      "original": "Igbo",
      "es": "Igbo",
      "en": "Igbo"
    },
    "code": "ig"
  },
  {
    "language": {
      "original": "Bahasa Indonesia",
      "es": "Indonesio",
      "en": "Indonesian"
    },
    "code": "id",
    "name": {
      "common": "Indonesia",
      "official": "Republic of Indonesia",
      "nativeName": {
        "ind": {
          "official": "Republik Indonesia",
          "common": "Indonesia"
        }
      }
    }
  },
  {
    "language": {
      "original": "English",
      "es": "Inglés",
      "en": "English"
    },
    "code": "en"
  },
  {
    "language": {
      "original": "IALA",
      "es": "Interlingua",
      "en": "Interlingua"
    },
    "code": "ia"
  },
  {
    "language": {
      "original": "Gaeilge",
      "es": "Irlandés",
      "en": "Irish"
    },
    "code": "ga",
    "name": {
      "common": "Gabon",
      "official": "Gabonese Republic",
      "nativeName": {
        "fra": {
          "official": "République gabonaise",
          "common": "Gabon"
        }
      }
    }
  },
  {
    "language": {
      "original": "Íslenska",
      "es": "Islandés",
      "en": "Icelandic"
    },
    "code": "is",
    "name": {
      "common": "Iceland",
      "official": "Iceland",
      "nativeName": {
        "isl": {
          "official": "Ísland",
          "common": "Ísland"
        }
      }
    }
  },
  {
    "language": {
      "original": "Italiano",
      "es": "Italiano",
      "en": "Italian"
    },
    "code": "it",
    "name": {
      "common": "Italy",
      "official": "Italian Republic",
      "nativeName": {
        "ita": {
          "official": "Repubblica italiana",
          "common": "Italia"
        }
      }
    }
  },
  {
    "language": {
      "original": "日本語",
      "es": "Japonés",
      "en": "Japanese"
    },
    "code": "ja"
  },
  {
    "language": {
      "original": "Bahasa Jawa",
      "es": "Javanés",
      "en": "Javanese"
    },
    "code": "jv"
  },
  {
    "language": {
      "original": "қазақша",
      "es": "Kazako",
      "en": "Kazakh"
    },
    "code": "kk"
  },
  {
    "language": {
      "original": "(ລາວ",
      "es": "Laosiano",
      "en": "Lao"
    },
    "code": "lo"
  },
  {
    "language": {
      "original": "Latina",
      "es": "Latín",
      "en": "Latin"
    },
    "code": "la",
    "name": {
      "common": "Laos",
      "official": "Lao People's Democratic Republic",
      "nativeName": {
        "lao": {
          "official": "ສາທາລະນະ ຊາທິປະໄຕ ຄົນລາວ ຂອງ",
          "common": "ສປປລາວ"
        }
      }
    }
  },
  {
    "language": {
      "original": "Latviešu",
      "es": "Letón",
      "en": "Latvian"
    },
    "code": "lv",
    "name": {
      "common": "Latvia",
      "official": "Republic of Latvia",
      "nativeName": {
        "lav": {
          "official": "Latvijas Republikas",
          "common": "Latvija"
        }
      }
    }
  },
  {
    "language": {
      "original": "Lingala",
      "es": "Lingala",
      "en": "Lingala"
    },
    "code": "ln"
  },
  {
    "language": {
      "original": "Lietuvių",
      "es": "Lituano",
      "en": "Lithuanian"
    },
    "code": "lt",
    "name": {
      "common": "Lithuania",
      "official": "Republic of Lithuania",
      "nativeName": {
        "lit": {
          "official": "Lietuvos Respublikos",
          "common": "Lietuva"
        }
      }
    }
  },
  {
    "language": {
      "original": "Lëtzebuergesch",
      "es": "Luxemburgués",
      "en": "Luxembourgish, Letzeburgesch"
    },
    "code": "lb",
    "name": {
      "common": "Lebanon",
      "official": "Lebanese Republic",
      "nativeName": {
        "ara": {
          "official": "الجمهورية اللبنانية",
          "common": "لبنان"
        },
        "fra": {
          "official": "République libanaise",
          "common": "Liban"
        }
      }
    }
  },
  {
    "language": {
      "original": "Македонски",
      "es": "Macedonio",
      "en": "Macedonian"
    },
    "code": "mk",
    "name": {
      "common": "North Macedonia",
      "official": "Republic of North Macedonia",
      "nativeName": {
        "mkd": {
          "official": "Република Северна Македонија",
          "common": "Македонија"
        }
      }
    }
  },
  {
    "language": {
      "original": "മലയാളം",
      "es": "Malabar",
      "en": "Malayalam"
    },
    "code": "ml",
    "name": {
      "common": "Mali",
      "official": "Republic of Mali",
      "nativeName": {
        "fra": {
          "official": "République du Mali",
          "common": "Mali"
        }
      }
    }
  },
  {
    "language": {
      "original": "Bahasa Melayu",
      "es": "Malayo",
      "en": "Malay"
    },
    "code": "ms",
    "name": {
      "common": "Montserrat",
      "official": "Montserrat",
      "nativeName": {
        "eng": {
          "official": "Montserrat",
          "common": "Montserrat"
        }
      }
    }
  },
  {
    "language": {
      "original": "Malagasy",
      "es": "Malgache",
      "en": "Malagasy"
    },
    "code": "mg",
    "name": {
      "common": "Madagascar",
      "official": "Republic of Madagascar",
      "nativeName": {
        "fra": {
          "official": "République de Madagascar",
          "common": "Madagascar"
        },
        "mlg": {
          "official": "Repoblikan'i Madagasikara",
          "common": "Madagasikara"
        }
      }
    }
  },
  {
    "language": {
      "original": "bilMalti",
      "es": "Maltés",
      "en": "Maltese"
    },
    "code": "mt",
    "name": {
      "common": "Malta",
      "official": "Republic of Malta",
      "nativeName": {
        "eng": {
          "official": "Republic of Malta",
          "common": "Malta"
        },
        "mlt": {
          "official": "Repubblika ta ' Malta",
          "common": "Malta"
        }
      }
    }
  },
  {
    "language": {
      "original": "Gaelg",
      "es": "Manés",
      "en": "Manx"
    },
    "code": "gv"
  },
  {
    "language": {
      "original": "Māori",
      "es": "Maorí",
      "en": "Māori"
    },
    "code": "mi"
  },
  {
    "language": {
      "original": "मराठी",
      "es": "Marati",
      "en": "Marathi (Marāṭhī)"
    },
    "code": "mr",
    "name": {
      "common": "Mauritania",
      "official": "Islamic Republic of Mauritania",
      "nativeName": {
        "ara": {
          "official": "الجمهورية الإسلامية الموريتانية",
          "common": "موريتانيا"
        }
      }
    }
  },
  {
    "language": {
      "original": "Moldoveana",
      "es": "Moldavo"
    },
    "code": "mo",
    "name": {
      "common": "Macau",
      "official": "Macao Special Administrative Region of the People's Republic of China",
      "nativeName": {
        "por": {
          "official": "Região Administrativa Especial de Macau da República Popular da China",
          "common": "Macau"
        },
        "zho": {
          "official": "中华人民共和国澳门特别行政区",
          "common": "澳门"
        }
      }
    }
  },
  {
    "language": {
      "original": "Монгол",
      "es": "Mongol",
      "en": "Mongolian"
    },
    "code": "mn",
    "name": {
      "common": "Mongolia",
      "official": "Mongolia",
      "nativeName": {
        "mon": {
          "official": "Монгол улс",
          "common": "Монгол улс"
        }
      }
    }
  },
  {
    "language": {
      "original": "Nahuatl",
      "es": "Náhuatl"
    },
    "code": "ah"
  },
  {
    "language": {
      "original": "Ekakairũ Naoero",
      "es": "Nauruano",
      "en": "Nauru"
    },
    "code": "na",
    "name": {
      "common": "Namibia",
      "official": "Republic of Namibia",
      "nativeName": {
        "afr": {
          "official": "Republiek van Namibië",
          "common": "Namibië"
        },
        "deu": {
          "official": "Republik Namibia",
          "common": "Namibia"
        },
        "eng": {
          "official": "Republic of Namibia",
          "common": "Namibia"
        },
        "her": {
          "official": "Republic of Namibia",
          "common": "Namibia"
        },
        "hgm": {
          "official": "Republic of Namibia",
          "common": "Namibia"
        },
        "kwn": {
          "official": "Republic of Namibia",
          "common": "Namibia"
        },
        "loz": {
          "official": "Republic of Namibia",
          "common": "Namibia"
        },
        "ndo": {
          "official": "Republic of Namibia",
          "common": "Namibia"
        },
        "tsn": {
          "official": "Lefatshe la Namibia",
          "common": "Namibia"
        }
      }
    }
  },
  {
    "language": {
      "original": "Norsk",
      "es": "Noruego",
      "en": "Norwegian"
    },
    "code": "no",
    "name": {
      "common": "Norway",
      "official": "Kingdom of Norway",
      "nativeName": {
        "nno": {
          "official": "Kongeriket Noreg",
          "common": "Noreg"
        },
        "nob": {
          "official": "Kongeriket Norge",
          "common": "Norge"
        },
        "smi": {
          "official": "Norgga gonagasriika",
          "common": "Norgga"
        }
      }
    }
  },
  {
    "language": {
      "original": "Occitan",
      "es": "Occitano",
      "en": "Occitan"
    },
    "code": "oc"
  },
  {
    "language": {
      "original": "Papiamento",
      "es": "Papiamento"
    },
    "code": "ap"
  },
  {
    "language": {
      "original": "فارسی",
      "es": "Persa",
      "en": "Persian"
    },
    "code": "fa"
  },
  {
    "language": {
      "original": "Polski",
      "es": "Polaco",
      "en": "Polish"
    },
    "code": "pl",
    "name": {
      "common": "Poland",
      "official": "Republic of Poland",
      "nativeName": {
        "pol": {
          "official": "Rzeczpospolita Polska",
          "common": "Polska"
        }
      }
    }
  },
  {
    "language": {
      "original": "Português",
      "es": "Portugués",
      "en": "Portuguese"
    },
    "code": "pt",
    "name": {
      "common": "Portugal",
      "official": "Portuguese Republic",
      "nativeName": {
        "por": {
          "official": "República português",
          "common": "Portugal"
        }
      }
    }
  },
  {
    "language": {
      "original": "ਪੰਜਾਬੀ",
      "es": "Punjabí",
      "en": "Panjabi, Punjabi"
    },
    "code": "pa",
    "name": {
      "common": "Panama",
      "official": "Republic of Panama",
      "nativeName": {
        "spa": {
          "official": "República de Panamá",
          "common": "Panamá"
        }
      }
    }
  },
  {
    "language": {
      "original": "Runa Simi",
      "es": "Quechua",
      "en": "Quechua"
    },
    "code": "qu"
  },
  {
    "language": {
      "original": "Kırgızca",
      "es": "Quirguiz",
      "en": "Kirghiz, Kyrgyz"
    },
    "code": "ky",
    "name": {
      "common": "Cayman Islands",
      "official": "Cayman Islands",
      "nativeName": {
        "eng": {
          "official": "Cayman Islands",
          "common": "Cayman Islands"
        }
      }
    }
  },
  {
    "language": {
      "original": "Rumantsch",
      "es": "Romanche",
      "en": "Romansh"
    },
    "code": "rm"
  },
  {
    "language": {
      "original": "Română",
      "es": "Rumano",
      "en": "Romanian, Moldavian, Moldovan"
    },
    "code": "ro",
    "name": {
      "common": "Romania",
      "official": "Romania",
      "nativeName": {
        "ron": {
          "official": "România",
          "common": "România"
        }
      }
    }
  },
  {
    "language": {
      "original": "Русский",
      "es": "Ruso",
      "en": "Russian"
    },
    "code": "ru",
    "name": {
      "common": "Russia",
      "official": "Russian Federation",
      "nativeName": {
        "rus": {
          "official": "Российская Федерация",
          "common": "Россия"
        }
      }
    }
  },
  {
    "language": {
      "original": "Sardu",
      "es": "Sardo",
      "en": "Sardinian"
    },
    "code": "sc",
    "name": {
      "common": "Seychelles",
      "official": "Republic of Seychelles",
      "nativeName": {
        "crs": {
          "official": "Repiblik Sesel",
          "common": "Sesel"
        },
        "eng": {
          "official": "Republic of Seychelles",
          "common": "Seychelles"
        },
        "fra": {
          "official": "République des Seychelles",
          "common": "Seychelles"
        }
      }
    }
  },
  {
    "language": {
      "original": "Српски / Srpski",
      "es": "Serbio",
      "en": "Serbian"
    },
    "code": "sr",
    "name": {
      "common": "Suriname",
      "official": "Republic of Suriname",
      "nativeName": {
        "nld": {
          "official": "Republiek Suriname",
          "common": "Suriname"
        }
      }
    }
  },
  {
    "language": {
      "original": "Sicilianu",
      "es": "Siciliano"
    },
    "code": "cn",
    "name": {
      "common": "China",
      "official": "People's Republic of China",
      "nativeName": {
        "zho": {
          "official": "中华人民共和国",
          "common": "中国"
        }
      }
    }
  },
  {
    "language": {
      "original": "Soomaaliga",
      "es": "Somalí",
      "en": "Somali"
    },
    "code": "so",
    "name": {
      "common": "Somalia",
      "official": "Federal Republic of Somalia",
      "nativeName": {
        "ara": {
          "official": "جمهورية الصومال‎‎",
          "common": "الصومال‎‎"
        },
        "som": {
          "official": "Jamhuuriyadda Federaalka Soomaaliya",
          "common": "Soomaaliya"
        }
      }
    }
  },
  {
    "language": {
      "original": "Svenska",
      "es": "Sueco",
      "en": "Swedish"
    },
    "code": "sv",
    "name": {
      "common": "El Salvador",
      "official": "Republic of El Salvador",
      "nativeName": {
        "spa": {
          "official": "República de El Salvador",
          "common": "El Salvador"
        }
      }
    }
  },
  {
    "language": {
      "original": "Kiswahili",
      "es": "Swahili",
      "en": "Swahili"
    },
    "code": "sw"
  },
  {
    "language": {
      "original": "Tagalog",
      "es": "Tagalo",
      "en": "Tagalog"
    },
    "code": "tl",
    "name": {
      "common": "Timor-Leste",
      "official": "Democratic Republic of Timor-Leste",
      "nativeName": {
        "por": {
          "official": "República Democrática de Timor-Leste",
          "common": "Timor-Leste"
        },
        "tet": {
          "official": "Repúblika Demokrátika Timór-Leste",
          "common": "Timór-Leste"
        }
      }
    }
  },
  {
    "language": {
      "original": "ไทย",
      "es": "Tailandés",
      "en": "Thai"
    },
    "code": "th",
    "name": {
      "common": "Thailand",
      "official": "Kingdom of Thailand",
      "nativeName": {
        "tha": {
          "official": "ราชอาณาจักรไทย",
          "common": "ประเทศไทย"
        }
      }
    }
  },
  {
    "language": {
      "original": "தமிழ்",
      "es": "Tamil",
      "en": "Tamil"
    },
    "code": "ta"
  },
  {
    "language": {
      "original": "Tatarça",
      "es": "Tártaro",
      "en": "Tatar"
    },
    "code": "tt",
    "name": {
      "common": "Trinidad and Tobago",
      "official": "Republic of Trinidad and Tobago",
      "nativeName": {
        "eng": {
          "official": "Republic of Trinidad and Tobago",
          "common": "Trinidad and Tobago"
        }
      }
    }
  },
  {
    "language": {
      "original": "Qırım Tatarca",
      "es": "Tártaro"
    },
    "code": "rh"
  },
  {
    "language": {
      "original": "తెలుగు",
      "es": "tegulú",
      "en": "Telugu"
    },
    "code": "te"
  },
  {
    "language": {
      "original": "བོད་ཡིག",
      "es": "Tibetano",
      "en": "Tibetan Standard, Tibetan, Central"
    },
    "code": "bo",
    "name": {
      "common": "Bolivia",
      "official": "Plurinational State of Bolivia",
      "nativeName": {
        "aym": {
          "official": "Wuliwya Suyu",
          "common": "Wuliwya"
        },
        "grn": {
          "official": "Tetã Volívia",
          "common": "Volívia"
        },
        "que": {
          "official": "Buliwya Mamallaqta",
          "common": "Buliwya"
        },
        "spa": {
          "official": "Estado Plurinacional de Bolivia",
          "common": "Bolivia"
        }
      }
    }
  },
  {
    "language": {
      "original": "Türkçe",
      "es": "Turco",
      "en": "Turkish"
    },
    "code": "tr",
    "name": {
      "common": "Turkey",
      "official": "Republic of Turkey",
      "nativeName": {
        "tur": {
          "official": "Türkiye Cumhuriyeti",
          "common": "Türkiye"
        }
      }
    }
  },
  {
    "language": {
      "original": "تركمن / Туркмен",
      "es": "Turcomano",
      "en": "Turkmen"
    },
    "code": "tk",
    "name": {
      "common": "Tokelau",
      "official": "Tokelau",
      "nativeName": {
        "eng": {
          "official": "Tokelau",
          "common": "Tokelau"
        },
        "smo": {
          "official": "Tokelau",
          "common": "Tokelau"
        },
        "tkl": {
          "official": "Tokelau",
          "common": "Tokelau"
        }
      }
    }
  },
  {
    "language": {
      "original": "Українська",
      "es": "Ucraniano",
      "en": "Ukrainian"
    },
    "code": "uk"
  },
  {
    "language": {
      "original": "اردو",
      "es": "Urdu",
      "en": "Urdu"
    },
    "code": "ur"
  },
  {
    "language": {
      "original": "Ўзбек",
      "es": "Uzbeko",
      "en": "Uzbek"
    },
    "code": "uz",
    "name": {
      "common": "Uzbekistan",
      "official": "Republic of Uzbekistan",
      "nativeName": {
        "rus": {
          "official": "Республика Узбекистан",
          "common": "Узбекистан"
        },
        "uzb": {
          "official": "O'zbekiston Respublikasi",
          "common": "O‘zbekiston"
        }
      }
    }
  },
  {
    "language": {
      "original": "Walon",
      "es": "Valón",
      "en": "Walloon"
    },
    "code": "wa"
  },
  {
    "language": {
      "original": "Tiếng Việt",
      "es": "Vietnamita",
      "en": "Vietnamese"
    },
    "code": "vi",
    "name": {
      "common": "United States Virgin Islands",
      "official": "Virgin Islands of the United States",
      "nativeName": {
        "eng": {
          "official": "Virgin Islands of the United States",
          "common": "United States Virgin Islands"
        }
      }
    }
  },
  {
    "language": {
      "original": "Volapük",
      "es": "Volapuk",
      "en": "Volapük"
    },
    "code": "vo"
  },
  {
    "language": {
      "original": "isiXhosa",
      "es": "Xhosa",
      "en": "Xhosa"
    },
    "code": "xh"
  },
  {
    "language": {
      "original": "ייִדיש",
      "es": "Yidish",
      "en": "Yiddish"
    },
    "code": "yi"
  },
  {
    "language": {
      "original": "Yorùbá",
      "es": "Yoruba",
      "en": "Yoruba"
    },
    "code": "yo"
  },
  {
    "language": {
      "original": "isiZulu",
      "es": "Zulú"
    },
    "code": "zu"
  }
]

export default countriesLng;