import { Result } from "antd";
import { Suspense } from "react";
import { useState, lazy, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEstablishmentData } from "./actions/establishmentActions";
import { Loading } from "./layout/utils/AppLoading";
import { getID } from "./utils";

const MainContainer = lazy(() => import("./containers/MainContainer"));

const App = () => {

  const dispatch = useDispatch();
  const establishment = useSelector(state => state.establishment);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(true);

  const getInitialData = useCallback(async () => {
    const ID = await getID();
    if(!ID){
      setNotFound(true);
      setLoading(false);
      return;
    }
    const response = await dispatch(fetchEstablishmentData(ID));
    if (response !== 200) {
      setLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    if (!establishment) {
      getInitialData()
    }
    if(establishment){
      setLoading(false);
    }
  }, [getInitialData, establishment]);
  

  if (loading) return <Loading centered/>
  

  return (
    <Suspense fallback={<Loading centered />}>
      {
        !establishment && notFound
          ? <Result status="404" title={'NO SE HA ENCONTRADO EL ESTABLECIMIENTO'} />
          : <MainContainer/>
      }
    </Suspense>
  );
};

export default App;