import { keyBy } from "lodash";
import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";

export const fetchStaticTranslations = async(id) => {
	return axios.get(API.establishment.staticText(id))
	.then(({data}) => {
		return {ok : true, translations : keyBy(data, 'text')}
	})
	.catch(err => {
		return {ok : false, err : err};
	})
}