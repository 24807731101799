import { Divider } from "antd";
import { Plugins } from "@capacitor/core";
import { Fragment, useEffect, useState } from "react";
import {
  groupBy,
  isArray,
  isEmpty,
  isPlainObject,
  mapValues,
  reduce,
  toPairs,
} from "lodash";
import { LoremIpsum } from "lorem-ipsum";
import JSONPretty from "react-json-pretty";
import JSONTheme from "react-json-pretty/dist/monikai";
import moment from "moment";
import "moment/locale/es";
import { APP_COOKIE_NAME } from "../constants";
import {RRule, RRuleSet} from "rrule";

moment().locale("es");

const { Storage } = Plugins;

// ORDER generic prop for sorting
export const sortByOrder = (a, b) => {
  return a.order - b.order;
}

// OBJECTS
export const nest = function (seq, keys) {
  if (!keys.length) return seq;
  var first = keys[0];
  var rest = keys.slice(1);
  return mapValues(groupBy(seq, first), function (value) {
    return nest(value, rest);
  });
};

export const PLATFORM = {
  IOS: "iOS",
  ANDROID: "Android",
  WINDOWSPHONE: "Windows Phone",
};

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return PLATFORM.WINDOWSPHONE;
  }

  if (/android/i.test(userAgent)) {
    return PLATFORM.ANDROID;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return PLATFORM.IOS;
  }

  return "unknown";
};

// MATH
export const percentOf = (num1, num2) =>
  Math.round((num1 / num2) * 100 * 10) / 10;

export const taskPercent = (start, end) => {
  var now = moment();
  start = moment(start, "YYYY-MM-DD") || moment(now).startOf("day");
  end = moment(end) || moment(now).endOf("day");
  var totalMillisInRange = end.valueOf() - start.valueOf();
  var elapsedMillis = now.valueOf() - start.valueOf();
  return Math.round(Math.min(100, 100 * (elapsedMillis / totalMillisInRange)));
};

export const capitalizeFirst = (plateName) => {
	if(!plateName) return null;
	const lowerCaseplateName = plateName.toLowerCase().trim();
	return lowerCaseplateName.charAt(0).toUpperCase() + lowerCaseplateName.slice(1);
}

// DATES
export const dateFormatter = (date) => {
  return moment(date).format("L");
};

/**
 * @param {Date} date
 * @return {boolean}
 */
const filterDay = (date, day) => {
  return(
    day.getDate() === date.getDate() &&
    day.getMonth() === date.getMonth() &&
    day.getFullYear() === date.getFullYear()
  )
}

/**
 * @param {Date} date
 * @param {array} events
 */
export const filterByDay = (events, day) => {
  
  return Object.values(events).filter(event => {
    // not periodic
    if(!event.periodic){
      return daysBetween(day, new Date(event.day)) === 0;
    }

    day.setHours(0,0,0,0);
    const endDay = (() => {
      let aux = new Date(day);
      aux.setHours(23,59,59,999);
      return aux;
    })()

    // periodic
    const ruleSet = new RRuleSet();
    ruleSet.rrule(new RRule({
      freq : RRule[event.periodicInfo.freq.toUpperCase()],
      bymonth : event.periodicInfo.bymonth,
      bymonthday : event.periodicInfo.bymonthday,
      bysetpos : event.periodicInfo.bysetpos,
      byweekday : event.periodicInfo.weekDays,
      interval : event.periodicInfo.interval,
      dtstart : new Date(event.day),
      until : endDay
    }));

    const { exdate } = event.periodicInfo;
    if(exdate && exdate?.length > 0){
      exdate.forEach(ex_d => {
        const ex_date = new Date(ex_d);
        ex_date.setUTCHours(0,0,0,0);
        ruleSet.exdate(ex_date)
      });
    }

    if(ruleSet.all().filter((date) => filterDay(date, day)).length > 0){
      return true;
    }
    return false;
  })
}
/**
 * @param {Date} day
 * @param {Number} weekDay Monday = 0 
 */
export const filterByWeekDay = (service, weekDay) => {
  // if no schedule setted interpreted as all day
  if(!service.schedule || service.schedule.length === 0){
    return true;
  }
  return !arrayIsDeepEmpty(service.schedule[weekDay]);
}

//start => 0 = Monday, 1 = Tuesday, ...
export const getWeekDays = (locale = 'es', format = 'long', start = 0) => {
  var baseDate = new Date(Date.UTC(2017, 0, 2)); // just a Monday
  baseDate.setDate(baseDate.getDate() + start);
  var weekDays = [];
  for(let i = 0; i < 7; i++){       
    weekDays.push(baseDate.toLocaleDateString(locale, { weekday: format }));
    baseDate.setDate(baseDate.getDate() + 1);       
  }
  return weekDays;
}

export const getDaysFrom = (startDay, next, back) => {
  const baseDate = new Date(startDay);
  const days = [];
  if(back){
    for(let i=back; i>0;i--){
      const newDate = new Date(baseDate);
      newDate.setDate(newDate.getDate() - i);
      days.push(newDate);
    }
  }
  for(let i = 0; i < next; i++){
    const newDate = new Date(baseDate);
    newDate.setDate(newDate.getDate() + i);
    days.push(newDate);
  }
  return days;
}

export const daysBetween = (first, second) => {

  // Copy date parts of the timestamps, discarding the time parts.
  var one = new Date(first.getFullYear(), first.getMonth(), first.getDate());
  var two = new Date(second.getFullYear(), second.getMonth(), second.getDate());

  // Do the math.
  var millisecondsPerDay = 1000 * 60 * 60 * 24;
  var millisBetween = two.getTime() - one.getTime();
  var days = millisBetween / millisecondsPerDay;

  // Round down.
  return Math.floor(days);
};

const getDecimalHours = (time) => {
  const date = new Date(time);
  const hours = date.getHours();
  const decimalMinutes = date.getMinutes() / 60;
  return hours + decimalMinutes;
}


export const sortSchedules = (schA, schB) => {
  // only compare endTime
  const endA = getDecimalHours(schA.endTime);
  const endB = getDecimalHours(schB.endTime);
  
  return endA - endB;
}

export const getTimeString = (time, utc = false) => {

  const date = new Date(time);

  let hours ;
  let minutes;
  if(utc){
    minutes = date.getUTCMinutes();
    hours = date.getUTCHours();
  }
  if(!utc){
    minutes = date.getMinutes();
    hours = date.getHours();
  }

  const hLess10 = hours < 10;
  const mLess10 = minutes < 10;

  const hoursString = hLess10 ? `0${hours}` : hours;
  const minutesString = mLess10 ? `0${minutes}` : minutes;

  return `${hoursString}:${minutesString}`;
}

export const getTodayWeekDay = () => {
  const today = new Date(Date.now());
  // we assume Monday = 0, Date.getDay -> Sunday = 0
  return (today.getDay() + 6) % 7; 
}

export const randomDate = (start, end) => {
  var d = new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    ),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [day, month, year].join("/");
};

export const dateObjFormatter = (date, separator = "/") => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [day, month, year].join(separator);
};

export const momentToDate = (date) => {
  if (!date) {
    return new Date();
  }
  return new Date(date);
};

// FILES
export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const bytesToSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const blobToFile = (theBlob, fileName) => {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
};

// DEVICE
export const useIsMobile = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => setWidth(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  return width <= 768;
};

// DEBUG
export const JSONDebug = ({ data, active }) => {
  if (!active) {
    return null;
  }
  return (
    <Fragment>
      <Divider />
      <JSONPretty theme={JSONTheme} id="json-pretty" data={data || {}} />
    </Fragment>
  );
};

// FORM
const handleInitDataTypes = (data) => {
  if (isPlainObject(data) && data?.id) {
    return data?.id;
  }
  if (isPlainObject(data) && !data?.id) {
    return data;
  }
  if (isArray(data)) {
    const output = reduce(
      data,
      (acc, curr) => {
        const current = { ...curr };
        for (let prop in current) {
          if (isPlainObject(current[prop])) {
            current[prop] = current[prop]?.id;
          }
        }
        acc.push(current);
        return acc;
      },
      []
    );
    return output;
  }
  return data;
};

export const formatFormInitialData = (data) => {
  const fields = toPairs(data).reduce((flds, a) => {
    flds.push({ name: a[0], value: handleInitDataTypes(a[1]) });
    return flds;
  }, []);
  return fields;
};

// MOCK
export const mockedText = (textLenght = 1) => {
  const text = new LoremIpsum({
    sentencesPerParagraph: {
      max: 8,
      min: 4,
    },
    wordsPerSentence: {
      max: 16,
      min: 4,
    },
  });
  return text.generateSentences(textLenght);
};

// COOKIES
export const getID = async() => {
  let ID = await getCookie(APP_COOKIE_NAME)
  if (!ID) {
    const params = new URLSearchParams(window.location.search);
    ID = params.get("establishment")

    if(!ID) return null;
    
    setCookie(APP_COOKIE_NAME, ID);
  }
  return ID;
}

export const setCookie = async (key, value, exdays) => {
  await Storage.set({ key, value: JSON.stringify(value) });
};

export const getCookie = async (key) => {
  const c = await Storage.get({ key: key });
  return JSON.parse(c.value);
};

export const deleteCookie = async (key) => {
  await Storage.remove({ key });
};

/**
 *  @param {Array} array
 * @return {boolean} true if array is deeply empty
 * */
export const arrayIsDeepEmpty = array => {
  if(!isEmpty(array)) {
    let index = 0;
    let empty = false;
    while (index < array.length && empty){
      empty = arrayIsDeepEmpty(array[index]);
      index++;
    }
    return empty;
  }
  return true;
}

// export const setCookie = (cname, cvalue, exdays) => {
//     var d = new Date();
//     d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
//     var expires = "expires=" + d.toUTCString();
//     document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
// };

// export const getCookie = (cname) => {
//     var name = cname + "=";
//     var decodedCookie = decodeURIComponent(document.cookie);
//     var ca = decodedCookie.split(';');
//     for (var i = 0; i < ca.length; i++) {
//         var c = ca[i];
//         while (c.charAt(0) === ' ') {
//             c = c.substring(1);
//         }
//         if (c.indexOf(name) === 0) {
//             return c.substring(name.length, c.length);
//         }
//     }
//     return "";
// };

// export const deleteCookie = (cname) => {
//     document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
// };
