import { createSelector } from 'reselect';
import moment from 'moment';
import 'moment/locale/es';
import { filterByDay,filterByWeekDay, sortByOrder } from '../../utils';
moment().locale('es');

/* APP */
export const getCurrentRoute = createSelector(state => state.currentRoute, currentRoute => currentRoute);
export const getCurrentRouteTitle = createSelector(getCurrentRoute, route => route.title);
export const getSideMenuState = createSelector(state => state.app, app => app.isCollapsedSideMenu);
export const getCurrentReportDateFilter = createSelector(state => state.app, app => app?.reportSharedData?.currentDateFilter);

/* ESTABLISHMENT */
export const getEstablishment = createSelector(state => state.establishment, establishment => establishment);
export const getEstablishmentLng = createSelector(getEstablishment, establishment => establishment?.language);
export const getEstablishmentColor = createSelector(getEstablishment, establishment => establishment?.color);
export const getEstablishmentLogo = createSelector(getEstablishment, establishment => establishment?.logo?.url);
export const getEstablishmentBg = createSelector(getEstablishment, establishment => establishment?.background?.url);
export const getEstablishmentId = createSelector(getEstablishment, establishment => establishment?._id);
export const getEstablishmentGallery = createSelector(getEstablishment, establishment => establishment?.gallery?.sort(sortByOrder) || []);
export const getVWId = createSelector(getEstablishment, establishment => establishment.vw_info.hasVW ? establishment.vw_info.tpv : null);

export const getHomePage = createSelector(state => state.homepage, homePage => homePage);

export const getRestaurants = createSelector(state => state.restaurants, restaurants => restaurants);
export const getRestaurantMenus = (restId) => createSelector(getRestaurants, restaurants => restaurants[restId] ? restaurants[restId].menu : []);
export const getInfo = createSelector(state => state.info, info => info);
export const getServices = createSelector(state => state.services, services => services);
export const getServicesByWeekDay = (weekDay) => createSelector(getServices, services => services ? services.filter(service => filterByWeekDay(service, weekDay)) : null)
export const getZones = createSelector(state => state.zones, zones => zones);
export const getZoneById = (id) => createSelector(getZones, zones => zones ? zones[id] : null);
export const getActivities = createSelector(state => state.activities, activities => activities);
export const getScheduled = createSelector(state => state.scheduled, scheduled => scheduled);
export const getScheduledByDay = (day) => createSelector(getScheduled, scheduled => filterByDay(scheduled, day))
export const getCustomPages = createSelector(state => state.pages, pages => pages);
export const getCustomPageById = (id) => createSelector(getCustomPages, pages => (pages && pages.find) ? pages?.find(page => page._id === id) : null);

/* VW */
export const getVWData = createSelector(state => state.vw, vw => vw);
export const getTPVCats = createSelector(getVWData, vw => vw.cats);
export const getTPVPlatesByCat = (cat) => createSelector(getVWData, vw => vw.plates.filter(plate => plate.tipoPlato === cat));

/* ROUTES */
export const getRoutes = createSelector(state => state.routes, routes => routes);
